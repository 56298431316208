import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hydrulics Structures`}</h1>
    <h2>{`Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1P8c6_H0z1xhZiOkDmdp7EmIUDwrc7SL3"
      }}>{`Download: Irrigation and Hydraulic Structures Theory, Design & Practice By Dr. Iqbal Ali`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1G3UG04skC5V1uwhOcc-RutlDjqCx0vFX"
      }}>{`Download: Design of Hydraulic gates by Paulo C.F. Erbisti`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=12P0IWCOpLrMCtnPLIo-9IxYZZVs9cReN"
      }}>{`Download: Hydraulic Design handbook`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1T_QwWdEy6j7CsUkVtqmbxI2ciTDfiGmH"
      }}>{`Download: Hydraulic Design of Energy Dissipators for Culverts and Channels by FHWA`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1QXbLAjrJU6v29RgiIrppelZ3UVaiTjh2"
      }}>{`Download: Hydraulic Structures 4th ed by P. Novak, A.I.B Moffat, C. Nullari, R. Narayanan`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1mXW_SOpDKHY5WB-F209L8ScXzF_H4byf"
      }}>{`Download: Hydraulic design of stilling basins and energy dissipators by USBR`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=18G_CBhiyCy1TAWVSMMj1fBlJI-eaC1m0"
      }}>{`Download: Design of small dams by USBR`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1AVdObgH0CLj3XZdyK9tYh3Bigy1V9F1n"
      }}>{`Download: Weir experiments, coefficients and formulas by USGS`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1kbrcYtMgKRJR99h5sg6ve2ApBSvOLveX"
      }}>{`Download: Irrigation engineering and hydraulic structures by Santosh Kumar Garg`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=19J6jb-4CUNq1Kx1bG2kFdTTFv-oYlN2F"
      }}>{`Download: Hydraulic Design of Straight compound channels Vol 1 by P. Ackers`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1BMp7UKl_ZqStwZBsKw7c5kGd9ZvMGpOn"
      }}>{`Download: Design of small canals by USBR`}</a></p>
    <h2>{`Lab Reports`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/estimation-of-coefficient-of-weir-for-ogee-weir/"
      }}>{`Experiment # 1: Determination of coefficient of weir of ogee weir`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/study-of-pressure-head-variation-of-flow-over-ogee-weir/"
      }}>{`Experiment # 2: Study of pressure head variation of flow over ogee weir`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/to-measure-energy-dissipation-downstream-of-spillway-by-various-energy-dissipation-arrangements/"
      }}>{`Experiment # 3: To measure energy dissipation downstream of spillway by various energy dissipation arrangements`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/determination-of-coefficient-of-discharge-of-syphoning-spillway/"
      }}>{`Experiment # 4: Determination of coefficient of discharge for siphon spillway`}</a></p>
    <h2>{`Lecture Slides`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1w01qM3XOygwK-XUwisqsiYMaNWOo484z"
      }}>{`Lecture 1: Introduction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1Wuge2OZWkoEujEB5P4HK61fjGq0vbrsU"
      }}>{`Lecture 2-7: Energy dissipation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1uaCRGsOTkg3eEHXYRNQMC9vgY8sLTutC"
      }}>{`Lecture 8: Cross drainage works`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=15oGGeMj0pcx84hnb1EDmKJKwkx1XKNRw"
      }}>{`Lecture 9: Pumping stations`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1XfyeWh5L4P59itacuPrcys9LhBgbiTE3"
      }}>{`Lecture 10: Gravity dams`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1EyH1sPnxh5Gbn7YsP-zXKAPJRTjCtKRs"
      }}>{`Lecture 11: Buttress arch dams`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1NfcSRRwuJyCrV0s8QAfDNy5_C_S6kBLG"
      }}>{`Lecture 12: Design Features and Construction of Gravity Dams`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=18D4Tl7OzmQ7XYfpj4VZ4jhszJj_znjOi"
      }}>{`Lecture 13: Gates and valves`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1BKQDX0HW3HlWaCcYp20Tx5hc3aa8mO8e"
      }}>{`Lecture 14: Embankment dams (Part 1)`}</a>{`
`}<a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1Phd-l7T_mulG7mWxm-YWgSmWQSUSO1eS"
      }}>{`Lecture 14:  Embankment dams (Part 2)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=14Tei_0xXDhNMZjUQRyBFiEXjbSvMM85O"
      }}>{`Lecture 15: Dams safety and equipment`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/hydraulic-structures-ogee-spillway-USBR-problem.xlsx"
      }}>{`Download: Excel sheet for Design of an uncontrolled overflow ogee crest for chute spillway, Design of side channel spillway, Reference Ch=9 of USBR Design of Small Dams`}</a></p>
    <h2>{`Student’s presentations`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1lDKGdaFNi_pbR6mq12s6OZWx81VTXkKd"
      }}>{`Design of barrage for sub surface flow condition`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=12q4wtuvg5atjQXOr5FTpC90cITcmp97p"
      }}>{`Pumping stations`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1_oV6gaEdFvGt-bSjX5fszRx615c1hQpr"
      }}>{`Gates and valves`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1UV5ZzUYOFGI4WVFBKoxDgnX8jGmbi7sQ"
      }}>{`Design review of khanki barrage`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1NtL36V6_3vcMXEXlbXsJBEPTkZlvUL2z"
      }}>{`Dams safety and equipment`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1Omyja6xDLZPeP-eyVcVYkusTXCAV0ti1"
      }}>{`Khanki barrage introduction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=119Em52oVqd05mR5MJfShTgOPsfaPgEX-"
      }}>{`Solid and slotted buckets`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1SftlJOb9zG5frucIeEAxAQXxTdankpI-"
      }}>{`Instrumentation in dams`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1_NAjNUp0BKPI3mYEKE9UkN3_MWFgYvSf"
      }}>{`Super passage`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1sNzDw5dhY6U32I5QvB-yNB_flMMIfQJe"
      }}>{`Design of barrage for over flow condition`}</a></p>
    <h2>{`Notes`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/25.-Hydraulics-structures-for-flow-diversions-and-storage-Structures-for-flow-diversion.pdf"
      }}>{`25. Hydraulics structures for flow diversions and storage (Structures for flow diversion)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/26.-Hydraulics-structures-for-flow-diversions-and-storage-Design-of-main-diversion-structure-of-barrage.pdf"
      }}>{`26. Hydraulics structures for flow diversions and storage (Design of main diversion structure of barrage)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/27.-Hydraulics-structures-for-flow-diversions-and-storage-Design-of-barrage-appertenance-structure-and-rules-for-barrage-operation.pdf"
      }}>{`27. Hydraulics structures for flow diversions and storage (Design of barrage appertenance structure and rules for barrage operation)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/28.-Hydraulics-structures-for-flow-diversions-and-storage-Structures-for-water-storage.pdf"
      }}>{`28. Hydraulics structures for flow diversions and storage (Structures for water storage)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/29.-Hydraulics-structures-for-flow-diversions-and-storage-Planning-of-water-storage-reservoirs.pdf"
      }}>{`29. Hydraulics structures for flow diversions and storage (Planning of water storage reservoirs)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/30.-Hydraulics-structures-for-flow-diversions-and-storage-Design-and-construction-of-concrete-gravity-dams.pdf"
      }}>{`30. Hydraulics structures for flow diversions and storage (Design and construction of concrete gravity dams)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/31.-Hydraulics-structures-for-flow-diversions-and-storage-Spillways-and-energy-dissipators.pdf"
      }}>{`31. Hydraulics structures for flow diversions and storage (Spillways and energy dissipators)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/32.-Hydraulics-structures-for-flow-diversions-and-storage-Reservoir-outlet-works.pdf"
      }}>{`32. Hydraulics structures for flow diversions and storage (Reservoir outlet works)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/33.-Hydraulics-structures-for-flow-diversions-and-storage-Gates-and-valves-for-flow-control.pdf"
      }}>{`33. Hydraulics structures for flow diversions and storage (Gates and valves for flow control)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/37.-Management-of-water-resources-River-training-and-bank-protection-works.pdf"
      }}>{`37. Management of water resources (River training and bank protection works)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/38.-Management-of-water-resources-Drought-and-flood-management.pdf"
      }}>{`38. Management of water resources (Drought and flood management)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/04/39.-Management-of-water-resources-Remote-sensing-and-GIS-for-water-resource-management.pdf"
      }}>{`39. Management of water resources (Remote sensing and GIS for water resource management)`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      